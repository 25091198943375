<template>
  <div class="profile__info">
    <div class="profile__header">
      <h3 class="profile__fullname">
        {{ user?.name }}
      </h3>
      <span class="profile__role">
        {{ getRole }}
      </span>
      <a :href="user?.phone" type="tel" class="profile__tel">
        {{ user?.phone }}
      </a>
    </div>
    <ul class="profile__list">
      <li class="profile__item">
        <h4 class="profile__item-title">Тип организации:</h4>
        <span class="profile__item-text">
          {{ user?.organization_type?.name }}
        </span>
      </li>
      <li class="profile__item">
        <h4 class="profile__item-title">Тип контрагента:</h4>
        <span class="profile__item-text">
          {{ user?.partner_type?.name }}
        </span>
      </li>
      <li class="profile__item">
        <h4 class="profile__item-title">Адрес электронной почты:</h4>

        <a :href="'mailto:' + user?.email" class="btn btn-link btn-link-classic" type="email">
          {{ user?.email }}
        </a>

        </li>
    </ul>
  </div>
  <span
    :class="['profile__status', isAccreditation ? 'profile__status--success' : 'profile__status--attention']"
  >
    {{ descriptionAccreditation }}
  </span>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { getDescriptionAccreditation, getRoleName } from '@/utils/utils'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()

    const user = computed(() => {
      if (route.params.id) {
        return store.getters['user/getUser']
      }
      return store.getters['auth/getUser']
    })

    const getRole = computed(() => {
      if (route.params.id) {
        return getRoleName(getRoles())
      } else {
        return getRoleName(getRoles())
      }
    })

    const isAccreditation = computed(() => {
      const role = getRoles()
      return role && role !== 'user'
    })

    const descriptionAccreditation = computed(() => {
      return getDescriptionAccreditation(getRoles())
    })

    function getRoles() {
      return user.value?.roles?.[0].name
    }

    return {
      user,
      isAccreditation,
      getRole,
      descriptionAccreditation
    }
  }
}
</script>
