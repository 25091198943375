<template>
  <VLink
    v-if="$route.params.id"
    @click="$router.push({ name: $route.meta.back })" class="mb-20">
    Назад
  </VLink>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <VProfile
    v-if="!isEdit"
    :class="classNameProfile"
    show-buttons
    @onEdit="goEdit"
    :is-disabled="isSend"
    :avatar="getAvatar"
    @onChangePassword="handlerClickPassword"
  >
    <VProfileInfo />
  </VProfile>

  <div class="block__tabs mb-40" v-if="!isEdit">
    <VTabs
      use-params
      is-small
      :items="adminProfile" />
  </div>

  <router-view/>
</template>

<script>
import { computed, inject, onBeforeMount, reactive, ref } from 'vue'

import { FormHandler } from '@/utils/use/formHandler'
import VProfile from '@/components/profile/VProfile'
import VProfileInfo from '@/components/profile/VProfileInfo'
import VTabs from '@/components/views/Tabs/VTabs'
import { adminProfile } from '@/utils/tabs'
import VLink from '@/components/ui/buttons/VLink'
import { goAccreditation } from '@/utils/goRoute'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { AUTH_CONTROLLER } from '@/utils/consts'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const authController = inject(AUTH_CONTROLLER)
    const isSend = ref(false)

    const goEdit = () => {
      router.push({ name: 'admin-profile-edit', params: { id: route.params.id } })
    }

    const user = reactive({})

    const getUser = computed(() => {
      return user.value
    })

    onBeforeMount(async () => {
      await store.dispatch('user/setUser', route.params.id)
      user.value = store.getters['user/getUser']
      if (route.params.id) {
        await router.push({ name: 'admin-profile-info', params: { id: route.params.id } })
      }
    })

    const isEdit = computed(() => {
      return router.currentRoute.value.name.includes('edit')
    })

    const isApplicant = computed(() => {
      return user.value?.roles?.[0]?.name === 'user' && !isEdit.value
    })

    const getAvatar = computed(() => {
      return store.getters['user/getUser']?.avatar?.[0][0].url
    })

    const classNameProfile = computed(() => {
      return isApplicant.value ? 'mb-30' : 'mb-60'
    })

    const changeIsChangePassword = async () => {
      await authController.changePasswordByAdmin(isSend.value, route.params.id)
    }

    const form = FormHandler(changeIsChangePassword)

    const handlerClickPassword = async () => {
      await form.handlerErrors()
    }

    return {
      getAvatar,
      handlerClickPassword,
      isApplicant,
      goEdit,
      isEdit,
      getUser,
      adminProfile,
      goAccreditation,
      changeIsChangePassword,
      classNameProfile,
      isSend
    }
  },
  components: {
    VProfile,
    VProfileInfo,
    VTabs,
    VLink
  }
}
</script>
